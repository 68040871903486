<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="1">
        <v-btn color="warning" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="11" class="text-h6 font-weight-regular"> Lavorazione carro {{ targaCarro }} </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card :color="colorRowCancellaCarroConvoglio">
          <v-card-title class="text-body-1 font-weight-light py-2 my-2 warning">
            GESTIONE CARRO
          </v-card-title>
          <v-card-text>
            <v-container fill-height fluid>
              <v-row align="center" justify="center">
                <v-col>
                  <v-btn @click="handleCaricoScarico(null, carroConvoglio)" :disabled="disableBtnScaricaCarroConvoglio" block x-large :color="colorBtnScaricaCarroConvoglio">
                    {{ textBtnScaricaCarroConvoglio }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="goCarroAssociaDanni(carroConvoglio)" block x-large :disabled="disableBtnDanniCarroConvoglio" color="orange lighten-5">
                    Danni
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="goCarroAssociaSigillo(carroConvoglio)" block x-large color="light-blue lighten-5" :disabled="disableBtnSigilliCarroConvoglio">
                    Sigilli
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="onOpenRifiutaCarroDialog" :disabled="disableBtnRifiutaCarroConvoglio" block x-large :color="colorBtnRifiutaCarroConvoglio">
                    Rifiuta
                  </v-btn>
                  <v-dialog v-model="rifiutaCarroDialog" max-width="500px">
                    <v-card-text style="background-color: #ffffff">
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              label="motivo rifiuto"
                              v-model.number="motivoRifiutoCarro"
                              :items="listMotivazioniRifiuto"
                              item-text="label"
                              item-value="id"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="notaRifiutoCarro" label="note" counter="250"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions style="background-color: #ffffff">
                      <v-spacer></v-spacer>
                      <v-btn text @click="saveCarroConvoglioMotivoRifiuto(carroConvoglio)">
                        Salva
                      </v-btn>
                      <v-btn text :disabled="disabledBtnAnnullaRifiutaCarroConvoglio" @click="rifiutaAnnullaRifiutoCarroConvoglio(carroConvoglio)"> Annulla Rifiuto</v-btn>
                      <v-btn text @click="rifiutaCarroDialog = false">
                        Chiudi
                      </v-btn>
                    </v-card-actions>
                  </v-dialog>
                </v-col>
                <v-col>
                  <v-btn
                    @click="cancellaAnnullaCancellaCarroConvoglio(carroConvoglio)"
                    :disabled="disableBtnCancellaCarroConvoglio"
                    block
                    x-large
                    :color="colorBtnCancellaCarroConvoglio"
                  >
                    {{ labelBtnCarroCancella }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="carroConvoglio && carroConvoglio.listUti && carroConvoglio.listUti.length > 0 && this.carroConvoglioCancellato === false">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-body-1 font-weight-light py-2 my-2 grey lighten-2">GESTIONE UTI </v-card-title>
          <v-card-text>
            <v-container fill-height fluid>
              <v-row align="center" justify="center" :class="colorRowUtiCancella(utiCarro)" v-for="utiCarro in carroConvoglio.listUti" :key="utiCarro.id">
                <v-col>
                  <v-container class="text-h6 font-weight-regular pa-0 ma-0">
                    <v-row class="pa-0 ma-0" justify="center">
                      <v-col cols="12" class="font-weight-black pa-0 ma-0">{{ displayUtiCarro(utiCarro) }}</v-col>
                    </v-row>

                    <v-row class="pa-0 ma-0" justify="center" v-if="utiCarro.areaScarico">
                      <v-col cols="2" class="pa-0 ma-0">
                        <v-icon medium color="success">mdi-arrow-right-bold</v-icon>
                      </v-col>
                      <v-col cols="10" class="pa-0 ma-0">{{ utiCarro.areaScarico }}</v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col>
                  <v-btn
                    @click="handleCaricoScarico(utiCarro, null)"
                    :disabled="disableUtiCarro(utiCarro, 'SCARICA')"
                    block
                    x-large
                    :color="getUtiStateColorText(utiCarro)"
                    :class="classRemainOnBoard(utiCarro)"
                  >
                    {{ textBtnScarica(utiCarro) }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="goUtiAssociaDanni(utiCarro)" block x-large color="orange lighten-5" :disabled="disableUtiCarro(utiCarro, 'DANNI')">
                    Danni
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="goUtiAssociaSigillo(utiCarro)" block x-large color="light-blue lighten-5" :disabled="disableUtiCarro(utiCarro, 'SIGILLI')">
                    Sigilli
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    @click="onOpenRifiutaUtiDialog(utiCarro)"
                    :disabled="disableUtiCarro(utiCarro, 'RIFIUTA')"
                    block
                    x-large
                    :color="colorBtnRifiuta(utiCarro)"
                    :class="classRemainOnBoard(utiCarro)"
                  >
                    Rifiuta
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn @click="cancellaAnnullaCancellaUtiCarro(utiCarro)" :disabled="disableUtiCarro(utiCarro, 'CANCELLA')" block x-large :color="colorBtnCancella(utiCarro)">
                    {{ labelBtnUtiCancella(utiCarro) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="rifiutaUtiDialog" max-width="500px">
      <v-card-text style="background-color: #ffffff">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete label="motivo rifiuto" v-model.number="motivoRifiutoUti" :items="listMotivazioniRifiuto" item-text="label" item-value="id"> </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="notaRifiutoUti" counter="250" label="note"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions style="background-color: #ffffff">
        <v-spacer></v-spacer>
        <v-btn text @click="saveUtiMotivoRifiuto(utiCarroSelected)">
          Salva
        </v-btn>
        <v-btn text :disabled="disabledBtnAnnullaRifiutaUti(utiCarroSelected)" @click="rifiutaAnnullaRifiutoUtiCarro(utiCarroSelected)">Annulla Rifiuto</v-btn>
        <v-btn text @click="rifiutaUtiDialog = false">Chiudi</v-btn>
      </v-card-actions>
    </v-dialog>

    <v-dialog v-model="dialogAreaStoccaggio" persistent width="600">
      <v-card>
        <v-card-title>{{ this.titoloCaricoScarico }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="areaStoccaggio" label="Area Stoccaggio" style="max-width:300px;" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="executeCaricoScarico(currentUtiCarro, currentCarro, areaStoccaggio)" small>{{ this.bottoneCaricoScarico }}</v-btn>
          <v-btn @click="dialogAreaStoccaggio = false" small>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import railsMixins from "../../components/rails/mixins/railsMixins";

export default {
  name: "LavorazioneCarroConvoglio",
  components: {},
  mixins: [railsMixins],
  props: ["carroConvoglioId"],
  data() {
    return {
      carroConvoglio: {},
      rifiutaUtiDialog: false,
      rifiutaCarroDialog: false,
      notaRifiutoUti: null,
      notaRifiutoCarro: null,
      motivoRifiutoUti: null,
      motivoRifiutoCarro: 0,
      listMotivazioniRifiuto: [],
      logMotivoRifiuto: [],
      utiCarroSelected: null,

      dialogAreaStoccaggio: false,
      areaStoccaggio: "",
      currentUtiCarro: {},
      currentCarro: {},
      bottoneCaricoScarico: "",
      titoloCaricoScarico: "",
    };
  },
  created() {},
  async mounted() {
    try {
      this.carroConvoglio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "carriConvoglio/" + this.carroConvoglioId);

      if (this.carroConvoglio.listUti && this.carroConvoglio.listUti.length > 0) {
        this.carroConvoglio.listUti = this.carroConvoglio.listUti.filter(utiWagon => utiWagon.tipoMovimento.toLowerCase() === "export");
      }

      const listDataMotivazioniRifiuto = await this.$api.get(this.$apiConfiguration.BASE_PATH + "motivazioniRifiuto/shortList");
      this.listMotivazioniRifiuto = [...listDataMotivazioniRifiuto.data];
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    labelBtnCarroCancella() {
      if (this.carroConvoglio.cancellato === true && this.carroConvoglioCancellato === true) {
        return "Ripristina";
      } else {
        return "Cancella";
      }
    },
    targaCarro() {
      if (this.carroConvoglio && this.carroConvoglio.visitaCarro) {
        return this.carroConvoglio.visitaCarro.targa;
      }
    },
    carroConvoglioScaricato() {
      var numScaricati = 0;
      var numDaScaricare = 0;
      if (this.carroConvoglioCancellato === false) {
        if (this.carroConvoglio && this.carroConvoglio.listUti) {
          this.carroConvoglio.listUti.forEach(utiCarro => {
            if (utiCarro.remainOnBoardScarico === false && utiCarro.stato !== "CANCELLATO") {
              numDaScaricare++;
              if (utiCarro.stato === "SCARICATO") {
                numScaricati++;
              }
            }
          });
        }
        if (this.carroConvoglio.listUti && this.carroConvoglio.listUti.length > 0 && numDaScaricare === numScaricati) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    carroConvoglioCancellato() {
      var numCancellati = 0;
      var numDaCancellare = 0;
      if (this.carroConvoglio && this.carroConvoglio.listUti) {
        this.carroConvoglio.listUti.forEach(utiCarro => {
          if (utiCarro.remainOnBoardScarico === false) {
            numDaCancellare++;
            if (utiCarro.stato === "CANCELLATO") {
              numCancellati++;
            }
          }
        });
      }

      if (this.carroConvoglio.cancellato === true || (this.carroConvoglio.listUti && this.carroConvoglio.listUti.length > 0 && numDaCancellare === numCancellati)) {
        return true;
      } else {
        return false;
      }
    },
    carroConvoglioRifiutato() {
      var numRifiutati = 0;
      var numDaRifiutare = 0;
      if (this.carroConvoglio && this.carroConvoglio.listUti) {
        this.carroConvoglio.listUti.forEach(utiCarro => {
          if (utiCarro.remainOnBoardScarico === false) {
            numDaRifiutare++;
            if (utiCarro.rifiutato === true) {
              numRifiutati++;
            }
          }
        });
      }
      if (numDaRifiutare === numRifiutati) {
        return true;
      } else {
        return false;
      }
    },
    disableBtnRifiutaCarroConvoglio() {
      if (this.carroConvoglioScaricato === true || this.carroConvoglioCancellato === true) {
        return "disabled";
      }
    },
    disableBtnDanniCarroConvoglio() {
      if (this.carroConvoglioCancellato === true) {
        return "disabled";
      }
    },
    disabledBtnAnnullaRifiutaCarroConvoglio() {
      if (this.carroConvoglioRifiutato === false) {
        return "disabled";
      }
    },
    disableBtnScaricaCarroConvoglio() {
      if (this.carroConvoglioRifiutato === true || this.carroConvoglioCancellato === true) {
        return "disabled";
      }
    },
    disableBtnSigilliCarroConvoglio() {
      if (this.carroConvoglioCancellato === true) {
        return "disabled";
      }
    },
    disableBtnCancellaCarroConvoglio() {
      if (this.carroConvoglioScaricato === true) {
        return "disabled";
      }
    },
    disableBtnSigilloCarroConvoglio() {
      if (this.carroConvoglio) {
        if (!this.carroConvoglio.visitaCarro.carro.tipoCarro.convenzionale) {
          return "disabled";
        }
      }
    },
    colorBtnRifiutaCarroConvoglio() {
      if (this.carroConvoglioRifiutato === true) {
        return "error";
      } else {
        return "red lighten-5";
      }
    },
    textBtnScaricaCarroConvoglio() {
      if (this.carroConvoglioScaricato === true) {
        return "SCARICATO";
      } else {
        return "DA SCARICARE";
      }
    },
    colorBtnScaricaCarroConvoglio() {
      if (this.carroConvoglioScaricato === true) {
        return this.getUtiStateColorTextByTipoStato("export", "scaricato");
      } else {
        return this.getUtiStateColorTextByTipoStato("export", "caricato");
      }
    },
    colorBtnCancellaCarroConvoglio() {
      if (this.carroConvoglioCancellato === true) {
        return "grey lighten-1";
      } else {
        return "red lighten-5";
      }
    },
    colorRowCancellaCarroConvoglio() {
      if (this.carroConvoglioCancellato === true) {
        return "grey lighten-3";
      } else {
        return "white";
      }
    },
  },
  methods: {
    labelBtnUtiCancella(utiCarro) {
      if (utiCarro.stato === "CANCELLATO") {
        return "Ripristina";
      } else {
        return "Cancella";
      }
    },
    disableUtiCarro(utiCarro, btnType) {
      if (
        (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) ||
        (btnType === "SCARICA" && (utiCarro.rifiutato === true || utiCarro.stato === "CANCELLATO")) ||
        (btnType === "RIFIUTA" && (utiCarro.stato === "SCARICATO" || utiCarro.stato === "CANCELLATO")) ||
        (btnType === "CANCELLA" && utiCarro.stato === "SCARICATO") ||
        ((btnType === "DANNI" || btnType === "SIGILLI") && utiCarro.stato === "CANCELLATO") ||
        utiCarro.visitaUti.gateOut!=null
      ) {
        return "disabled";
      }
    },
    disabledBtnAnnullaRifiutaUti(utiCarro) {
      if (utiCarro && utiCarro.rifiutato === false) {
        return "disabled";
      }
    },
    classRemainOnBoard(utiCarro) {
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        return "uti-rob";
      }
    },
    textBtnScarica(utiCarro) {
      if (utiCarro.stato === "SCARICATO") {
        return "SCARICATA";
      } else {
        return "DA SCARICARE";
      }
    },
    colorBtnRifiuta(utiCarro) {
      if (utiCarro.rifiutato) {
        return "error";
      } else {
        return "red lighten-5";
      }
    },
    colorBtnCancella(utiCarro) {
      if (utiCarro.stato === "CANCELLATO") {
        return "grey lighten-1";
      } else {
        return "red lighten-5";
      }
    },
    colorRowUtiCancella(utiCarro) {
      if (utiCarro.stato === "CANCELLATO") {
        return "py-3 my-0 grey lighten-3";
      } else {
        return "py-3 my-0 white";
      }
    },
    handleCaricoScarico(utiCarro, carro) {
      try {
        if (utiCarro) {
          //situazione uti carro
          var daScaricare = utiCarro.stato === "CARICATO" ? true : false;
          if (daScaricare) {
            this.openDialogCaricoScarico(utiCarro);
          } else {
            this.scaricaAnnullaScaricoUtiCarro(utiCarro, null).then(value => {
              if (value) {
                this.showSuccess("Operazione effettuata correttamente");
                if (this.carroConvoglio && this.carroConvoglio.listUti) {
                  this.carroConvoglio.listUti.forEach(element => {
                    if (element.id === value.id) {
                      element.areaScarico = value.areaScarico;
                    }
                  });
                }
              }
            });
          }
        } else {
          //situazione carro
          var daScaricare = this.carroConvoglioScaricato ? false : true;
          if (daScaricare) {
            this.openDialogCaricoScarico(null, carro);
          } else {
            this.scaricaAnnullaScaricoCarroConvoglio(carro, null).then(value => {
              if (value) {
                this.showSuccess("Operazione effettuata correttamente");
                if (this.carroConvoglio && this.carroConvoglio.listUti) {
                  this.carroConvoglio.listUti.forEach(element => {
                    if (element.id === value.id) {
                      element.areaScarico = value.areaScarico;
                    }
                  });
                }
              }
            });
          }
        }
      } catch (e) {
        this.showError(e);
      }
    },
    openDialogCaricoScarico(utiCarro, carro) {
      let statoAttuale = "";
      if (utiCarro) {
        this.currentUtiCarro = utiCarro;
        this.areaStoccaggio = utiCarro.areaScarico;
        this.currentCarro = null;
        statoAttuale = utiCarro.stato;
      } else {
        this.currentCarro = carro;
        this.currentUtiCarro = null;
        statoAttuale = this.carroConvoglioScaricato ? "SCARICATO" : "CARICATO";
        this.areaStoccaggio = null;
      }
      this.dialogAreaStoccaggio = true;
      this.bottoneCaricoScarico = statoAttuale === "SCARICATO" ? "CARICA" : "SCARICA";
      this.titoloCaricoScarico = statoAttuale === "SCARICATO" ? "CARICA" : "SCARICA";
    },
    executeCaricoScarico(utiCarro, carro, areaStoccaggio) {
      try {
        if (utiCarro) {
          this.scaricaAnnullaScaricoUtiCarro(utiCarro, areaStoccaggio).then(value => {
            if (value) {
              this.showSuccess("Operazione effettuata correttamente");
              this.carroConvoglio.listUti.forEach(element => {
                if (element.id === value.id) {
                  element.areaScarico = value.areaScarico;
                }
              });
            }
          });
        } else if (carro) {
          this.scaricaAnnullaScaricoCarroConvoglio(carro, areaStoccaggio);
        }
        this.dialogAreaStoccaggio = false;
      } catch (e) {
        this.showError(e);
      }
    },
    async scaricaAnnullaScaricoUtiCarro(utiCarro, areaStoccaggio) {
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        //we do nothing;
        return;
      }
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/scaricaUtiCarro";
      const statoPrec = utiCarro.stato;
      const nuovoStato = statoPrec === "SCARICATO" ? "CARICATO" : "SCARICATO";
      const reqObj = {
        utiCarroId: utiCarro.id,
        stato: nuovoStato,
        statoPrec: statoPrec,
        areaStoccaggio: areaStoccaggio,
      };
      try {
        var ret = await this.$api.post(fullPath, reqObj);
        console.log("scaricaAnnullaScaricoUtiCarro - ret", ret);
        utiCarro.stato = nuovoStato;
        utiCarro.areaStoccaggio = areaStoccaggio;
        return ret;
      } catch (e) {
        console.error(e);
        this.showError("Operazione non riuscita. " + e.message);
      }
    },

    async cancellaAnnullaCancellaUtiCarro(utiCarro) {
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/cancellaRiattivaUtiCarro";
      const nuovoStato = utiCarro.stato === "CANCELLATO" ? "CARICATO" : "CANCELLATO";
      try{
        const reqObj = {
          utiCarroId: utiCarro.id,
          stato: nuovoStato,
          statoPrec: utiCarro.stato,
        };
        var ret = await this.$api.post(fullPath, reqObj);
        utiCarro.stato = nuovoStato;
      } catch (e) {
        console.error(e);
        this.showError("Operazione non riuscita. " + e.message);
      }
    },

    async saveUtiMotivoRifiuto(utiCarro) {
      const createLogRifiutoUtiFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/createByUtiCarro";
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateRifiutato";

      utiCarro.rifiutato = true;
      const reqObj = {
        utiCarroId: utiCarro.id,
        rifiutato: utiCarro.rifiutato,
      };
      await this.$api.post(fullPath, reqObj);

      const reqCreateRifiutoUtiObj = {
        utiCarroId: utiCarro.id,
        carroConvoglioId: this.carroConvoglio.id,
        motivoRifiutoId: this.motivoRifiutoUti,
        notaRifiuto: this.notaRifiutoUti,
      };
      await this.$api.post(createLogRifiutoUtiFullPath, reqCreateRifiutoUtiObj);

      this.rifiutaUtiDialog = false;
    },
    async onOpenRifiutaCarroDialog() {
      this.motivoRifiutoCarro = null;
      this.notaRifiutoCarro = null;
      if (this.carroConvoglioRifiutato === true) {
        this.logMotivoRifiuto = await this.$api.get(this.$apiConfiguration.BASE_PATH + "logRifiuto/carroConvoglio/" + this.carroConvoglioId);

        if (this.logMotivoRifiuto.length > 0) {
          this.motivoRifiutoCarro = this.logMotivoRifiuto[0].carroMotivoRifiutoId;
          this.notaRifiutoCarro = this.logMotivoRifiuto[0].notaRifiutoCarro;
        }
      }
      this.rifiutaCarroDialog = true;
    },
    async onOpenRifiutaUtiDialog(utiCarro) {
      this.utiCarroSelected = utiCarro;

      this.motivoRifiutoUti = null;
      this.notaRifiutoUti = null;
      if (utiCarro.rifiutato === true) {
        this.logMotivoRifiuto = await this.$api.get(this.$apiConfiguration.BASE_PATH + "logRifiuto/utiCarro/" + utiCarro.id);
        if (this.logMotivoRifiuto.length > 0) {
          this.motivoRifiutoUti = this.logMotivoRifiuto[0].utiMotivoRifiutoId;
          this.notaRifiutoUti = this.logMotivoRifiuto[0].notaRifiutoUti;
        }
      }
      this.rifiutaUtiDialog = true;
    },
    async scaricaAnnullaScaricoCarroConvoglio(carroConvoglio, areaStoccaggio) {
      const updateStatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/scaricaUtiCarro";
      const updateStatoRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateStatoRifiutato";
      const nuovoStato = this.carroConvoglioScaricato ? "CARICATO" : "SCARICATO";
      try {
        this.carroConvoglio.listUti.forEach(async utiCarro => {
          const statoPrec = utiCarro.stato;
          if (utiCarro.remainOnBoardScarico === false && utiCarro.stato !== "CANCELLATO" && statoPrec != nuovoStato && utiCarro.visitaUti.gateOut == null) {
            console.log("richiesta stato " + nuovoStato + " visitaUti " + utiCarro.visitaUtiId);
            const reqObj = {
              utiCarroId: utiCarro.id,
              stato: nuovoStato,
              statoPrec: statoPrec,
              areaStoccaggio: areaStoccaggio,
            };
            await this.$api.post(updateStatoFullPath, reqObj);
            utiCarro.stato = nuovoStato;
            utiCarro.areaScarico = areaStoccaggio;
            if (nuovoStato === "SCARICATO") utiCarro.rifiutato = false;
          }
        });
      } catch (e) {
        console.error(e);
        this.showError("Operazione non riuscita. " + e.message);
      }
    },
    async cancellaAnnullaCancellaCarroConvoglio(carroConvoglio) {
      if (this.carroConvoglioCancellato === true) {
        await this.$api.trainData.deleteRiattivaCarroConvoglio(carroConvoglio.id, 0, false);
      } else {
        await this.$api.trainData.deleteRiattivaCarroConvoglio(carroConvoglio.id, 0, true);
      }

      this.carroConvoglio = await this.$api.get(this.$apiConfiguration.BASE_PATH + "carriConvoglio/" + carroConvoglio.id);
    },
    async rifiutaAnnullaRifiutoUtiCarro(utiCarro) {
      const fullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateRifiutato";
      if (utiCarro.rifiutato === true) {
        utiCarro.rifiutato = false;
      } else {
        utiCarro.rifiutato = true;
      }
      const reqObj = {
        utiCarroId: utiCarro.id,
        rifiutato: utiCarro.rifiutato,
      };
      await this.$api.post(fullPath, reqObj);
      const deleteMotiviRifiutoFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/deleteByUtiCarro";
      const reqDeleteObj = {
        utiCarroId: this.utiCarroId,
        carroConvoglioId: this.carroConvoglioId,
      };
      await this.$api.post(deleteMotiviRifiutoFullPath, reqDeleteObj);
      this.rifiutaUtiDialog = false;
    },
    async saveCarroConvoglioMotivoRifiuto(carroConvoglio) {
      carroConvoglio.listUti.forEach(async utiCarro => await this.updateStatoRifiutatoUti(utiCarro));
      await this.createLogRifiutoConvoglio(carroConvoglio);
      carroConvoglio.listUti.forEach(async utiCarro => await this.createLogRifiutoUti(utiCarro));
      this.rifiutaCarroDialog = false;
    },
    async createLogRifiutoConvoglio(carroConvoglio) {
      const createLogRifiutoCarroFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/createByCarroConvoglio";
      const reqCreateRifiutoObj = {
        carroConvoglioId: carroConvoglio.id,
        motivoRifiutoId: this.motivoRifiutoCarro,
        notaRifiuto: this.notaRifiutoCarro,
      };
      await this.$api.post(createLogRifiutoCarroFullPath, reqCreateRifiutoObj);
    },
    async updateStatoRifiutatoUti(utiCarro) {
      // const updateStatoRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateStatoRifiutato";
      if (utiCarro.remainOnBoardScarico === false && utiCarro.rifiutato === false) {
        const statoPrec = utiCarro.stato;
        utiCarro.rifiutato = true;
        utiCarro.stato = "CARICATO";
        await this.$api.trainData.updateStatoRifiutato(utiCarro.id, utiCarro.stato, statoPrec, utiCarro.rifiutato);
        // const reqObj = {
        //   utiCarroId: utiCarro.id,
        //   rifiutato: utiCarro.rifiutato,
        //   stato: utiCarro.stato,
        //   statoPrec: statoPrec,
        // };
        // await this.$api.post(updateStatoRifiutatoFullPath, reqObj);
      }
    },
    async createLogRifiutoUti(utiCarro) {
      const createLogRifiutoUtiFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/createByUtiCarro";
      if (utiCarro.rifiutato === true) {
        const reqCreateRifiutoUtiObj = {
          utiCarroId: utiCarro.id,
          carroConvoglioId: this.carroConvoglio.id,
          motivoRifiutoId: this.motivoRifiutoCarro,
          notaRifiuto: this.notaRifiutoCarro,
        };
        await this.$api.post(createLogRifiutoUtiFullPath, reqCreateRifiutoUtiObj);
      }
    },
    async rifiutaAnnullaRifiutoCarroConvoglio(carroConvoglio) {
      const deleteMotiviRifiutoFullPath = this.$apiConfiguration.BASE_PATH + "logRifiuto/deleteByCarroConvoglio";
      const updateRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateRifiutato";
      const updateStatoRifiutatoFullPath = this.$apiConfiguration.BASE_PATH + "utiCarro/updateStatoRifiutato";

      const reqDeleteObj = {
        carroConvoglioId: this.carroConvoglioId,
      };
      await this.$api.post(deleteMotiviRifiutoFullPath, reqDeleteObj);

      if (this.carroConvoglioRifiutato === true) {
        this.carroConvoglio.listUti.forEach(async utiCarro => {
          if (utiCarro.remainOnBoardScarico === false) {
            utiCarro.rifiutato = false;
            const reqObj = {
              utiCarroId: utiCarro.id,
              rifiutato: utiCarro.rifiutato,
            };
            await this.$api.post(updateRifiutatoFullPath, reqObj);
          }
        });
      } else {
        this.carroConvoglio.listUti.forEach(async utiCarro => {
          if (utiCarro.remainOnBoardScarico === false && utiCarro.rifiutato === false) {
            const statoPrec = utiCarro.stato;
            utiCarro.rifiutato = true;
            utiCarro.stato = "CARICATO";
            const reqObj = {
              utiCarroId: utiCarro.id,
              rifiutato: utiCarro.rifiutato,
              stato: utiCarro.stato,
              statoPrec: statoPrec,
            };
            await this.$api.post(updateStatoRifiutatoFullPath, reqObj);
          }
        });
      }
      this.rifiutaCarroDialog = false;
    },
    goCarroAssociaDanni(carroConvoglio) {
      this.$router.push({ path: "/callTreno/ConvoyAssociaDanni/" + carroConvoglio.id + "/" + " " + "/" + "CARRO" });
      return;
    },
    goUtiAssociaDanni(utiCarro) {
      this.$router.push({ path: "/callTreno/ConvoyAssociaDanni/" + this.carroConvoglio.id + "/" + utiCarro.id + "/" + "UTI" });
      return;
    },
    goCarroAssociaSigillo(carroConvoglio) {
      this.$router.push({ path: "/callTreno/ConvoyAssociaSigilli/" + carroConvoglio.id + "/" + "CARRO" + "/" + " " });
      return;
    },
    goUtiAssociaSigillo(utiCarro) {
      this.$router.push({ path: "/callTreno/ConvoyAssociaSigilli/" + this.carroConvoglio.id + "/" + "UTI" 
      + "/" + utiCarro.visitaUtiId +"/"+utiCarro.visitaUti.codiceUti});
      return;
    },
  },
};
</script>

<style scoped>
.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
</style>
